/* make sidebar nav vertical */
@media (min-width: 768px) {
    .sidebar-nav .navbar .navbar-collapse {
        padding: 0;
        max-height: none;
    }
    .sidebar-nav .navbar ul {
        float: none;
    }
    .sidebar-nav .navbar ul:not {
        display: block;
    }
    .sidebar-nav .navbar li {
        float: none;
        display: block;
    }
    .sidebar-nav .navbar li a {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

input.valuechanged {
	color:#a94442;
}
button.valuechanged {
	animation-name:blinkbackground;
	animation-iteration-count: infinite;
	animation-duration:2s;
}
@keyframes blinkbackground {
	25% {
		color:#a94442;
	}
	50% {
		color:#a94442;
	}
}

.bootstrap-select > .dropdown-toggle {
	min-height:2em;
}
